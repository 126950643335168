<template>
  <div
    v-if="termInfo"
    class="signup-term-info"
    v-html="termInfo"
  >
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  paymentCode: {
    type: String,
    default: ''
  },
  signupAccountInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const termInfo = computed(() => {
  const signupInfo = props.signupAccountInfo?.[props.paymentCode] || {}
  if (props.paymentCode === 'klarna-klarna' && signupInfo?.id) {
    return props.language?.SHEIN_KEY_PWA_34308
  } 
  return ''
})
</script>

<style lang="less">
.signup-term-info {
  margin-top: 4/37.5rem;
  .font-dpr(24px);
  line-height: 1;

  a {
      color: @sui_color_link;
      &:hover {
          color: @sui_color_link;
      }
  }
}
</style>
