<template>
  <div
    class="check-ppga-type"
    :class="{ 'bottom-line': showBottomLine, 'full-line': fullLine }"
    @click.stop.prevent
  >
    <ClientOnly>
      <s-radio
        v-model="selected"
        :label="checkedLabel"
        :reverse-select="true"
        theme="icon"
        gap="0"
        inline
        class="check-ppga-radio"
      >
        <span class="desc">{{ signUpDesc }}</span>
      </s-radio>
      <span v-if="extraDesc" class="extra-desc">{{ extraDesc }}</span>
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import Vue from 'vue'
import { Radio as SRadio } from '@shein/sui-mobile'
Vue.component('ClientOnly', ClientOnly)
export default {
  components: {
    SRadio
  },
  props: {
    paymentCode: {
      type: String,
      default: ''
    },
    vault: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    checkedLabel: {
      type: [String, Number, Boolean],
      default: ''
    },
    showBottomLine: {
      type: Boolean,
      default: false
    },
    fullLine: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    selected: {
      get() {
        return this.vault.type
      },
      set() {
        console.log('set')
        this.$emit('toggle', this.paymentCode)
      }
    },
    signUpDesc() {
      if (this.paymentCode === 'afterpay-card') {
        return this.language.SHEIN_KEY_PWA_30875
      } else if (this.paymentCode === 'afterpay-cashapp') {
        return this.language.SHEIN_KEY_PWA_34289
      } else if (this.paymentCode === 'dlocal-nupay') {
        return this.language.SHEIN_KEY_PWA_33933
      } else if (this.paymentCode === 'klarna-klarna') {
        return this.language.SHEIN_KEY_PWA_33936
      } else {
        return ''
      }
    },
    extraDesc() {
      if (this.paymentCode === 'klarna-klarna') {
        return this.language.SHEIN_KEY_PWA_33939
      } else if (this.paymentCode === 'dlocal-nupay') {
        return this.language.SHEIN_KEY_PWA_33788
      } else {
        return ''
      }
    },
  }
}
</script>

<style lang="less">
.check-ppga-type {
  padding: 12/75rem 0;
  .flexbox();
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  &.bottom-line {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 120%;
      height: 1px;
      background-color: @sui_color_gray_weak1;
      // transform: scaleY(0.5);
    }
    &.full-line:after {
      width: 100%;
    }
  }
  .desc {
    .font-dpr(24px);
    line-height: 1;
  }
  .check-ppga-radio {
    flex-shrink: 0;
    .sui-radio__check {
      .margin-r(4px);
    }
  }
  .extra-desc {
    display: block;
    width: 100%;
    flex-shrink: 0;
    margin-top: 7/37.5rem;
    .font-dpr(24px);
    color: #666;
  }
}
</style>
